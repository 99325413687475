import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import TripleColumnBlock from "@components/tripleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import ReactPlayer from 'react-player'

import {
  QuickLinkButton,
} from "@components/buttons"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Commercial Lending - Srdjan Gavrilovic - Websuasion Podcast"
        ogDescription="Srdjan Gavrilovic is a Senior Business Banker at First Citizens Bank in Atlanta. He finds creative ways to provide his clients more capital, better structured capital, and improve working capital management."
        image="/images/podcast/season-one/srdjan-gavrilovic.jpg"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
						<h1>Commercial Lending with Srdjan Gavrilovic</h1>
						<h3>First Citizens Bank</h3>
						<p>
							Srdjan Gavrilovic is a Senior Business Banker at First Citizens Bank in Atlanta. He finds creative ways to provide his clients more capital, better structured capital, and improve working capital management. He focuses on Healthcare, Real Estate and Equipment financing, Business Acquisition and partner buy-outs.
						</p>
						<p>
			        <QuickLinkButton
			          content={"Srdjan Gavrilovic's LinkedIn"}
			          url={"https://www.linkedin.com/in/srdjangavrilovic/"}
			          icon={"linkify"}
			        />
						</p>
						<p>
							In this episode of Websuasion Conversation, we discuss creating client relationships, building wealth, and the advantage of owning a business space.
						</p>
					</div>
        }
        textRight={
          <div className="centered">
            <img src="/images/podcast/season-one/srdjan-gavrilovic.jpg" className="rounded-square" alt="Commercial Lending with Srdjan Gavrilovic" width="300" />
					</div>
        }
      />
			<PageWrapper color="white">
	      <SingleColumnBlock
	        text={
	          <div>
							<h2>Listen Now</h2>
						  <AudioPlayer
						    src="https://websuasion-conversation.s3.amazonaws.com/WebsuasionConversationEpisode035-SrdjanGavrilovic.mp3"
						  />
							<h3>Subscribe to the podcast</h3>
							<p>
								<a href="https://itunes.apple.com/us/podcast/websuasion-conversation/id1454464479" target="_blank">
									<img src="/images/podcast/apple-podcasts-150x150.png" className="icon" alt="Websuasion Conversasion on Apple Podcasts" width="50" />
								</a>
								<a href="https://open.spotify.com/show/521lym6rKJPoWQZbeUROuG" target="_blank">
									<img src="/images/podcast/spotify-150x150.png" className="icon" alt="Websuasion Conversasion on Spotify" width="50" />
								</a>
							</p>
							<p>
								Podcast RSS feed:<br /><br />

								<span className="red">https://websuasion.com/podcast.xml</span>
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Introduction</h2>
							<p>
								Srdjan tells us about growing up in Belgrade Serbia before he moved to Atlanta in 1992
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/PAOaY9BcAaU'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>The Credit Path</h2>
							<p>
								Srdjan tells us about starting the management training program at Norwest Financial after he graduated with a degree in finance from Kennesaw State University.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/UaiZUXArGPE'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>An Early Interest in Finance</h2>
							<p>
								Srdjan talks to us about choosing his career path at an early age and the opportunity to study economics and business in high school.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/b3Xr2pXTCs8'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Commercial Lending</h2>
							<p>
								Srdjan talks about making his first commercial mortgage in 2003 before making a move to focus on commercial lending in 2006.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/UISra21YohU'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>A Family Controlled Bank</h2>
							<p>
								Srdjan tells us what it means to be a family controlled bank.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/kZCBpnNkdEs'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Professional Service Providers</h2>
							<p>
								Srdjan talks about expanding from lending to health care professionals to including other professional service providers.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/5uJSZX7yFBY'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Being Driven by the Vision</h2>
							<p>
								Srdjan talks about understanding his client's goals and vision for their business in order to structure their credit facilities properly.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/by1zfTz-1pc'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Creating Client Relationships</h2>
							<p>
								Srdjan talks about building long term relationships with his client by giving honest answers.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/K-QU7Qqj7co'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Advantages of Owning a Business Space</h2>
							<p>
								Srdjan discusses helping his clients build wealth by investing in a building rather than leasing.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/KaCeGK7ejfo'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>A Great Wealth Builder</h2>
							<p>
								Srdjan talks about the importance of planning and budgeting to account for maintenance and upkeep in a business space.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/J2vflfNB5zE'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Financial Flexibility</h2>
							<p>
								Srdjan talks about financial flexibility being the key to managing your company's finances.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/kitZRLrxR-E'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Adding Value</h2>
							<p>
								Srdjan talks about working with his clients to add value and establish relationships.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/AoOgZwZHMk4'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Building a Management Team</h2>
							<p>
								Srdjan discusses the importance of building a management team and structuring capital in order to meet business goals.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/njnX5WZzhoE'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Working On the Business</h2>
							<p>
								Srdjan talks about ways to work on the business and the benefits of having strong financial statements.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/bBj-DeD4VEQ'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>App Developer Checklist</h2>
							<p>
								You've been busy building your business, not learning all the ins and outs of application development. There's no reason you should know what it takes to develop a quality, maintainable application and bring it to market. But, before you contract with any firm (including ours), you should understand what is considered the standard. So, we've developed a handy two-page checklist. It will arm you the questions you need to ask before you move forward.
							</p>
						  <QuickLinkButton
						              content={"Get Your Copy"}
						              url={"/files/developer-checklist.pdf"}
						              icon={"file"}
						            />
	          </div>
	        }
	        textRight={
	          <div>
							<img src="/images/podcast/season-one/app-developer-checklist.png" className="rounded-image" alt="App Developer Checklist" width="620" />
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>"Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default IndexPage
